.team {
  max-width: 140rem;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 2rem;
  color: black;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  row-gap: 6rem;
}

.attorney {
  max-width: 20rem;
  background-color: #e9ecef;
  border: 3px solid black;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.75);
}

.photo {
  max-width: 20rem;
}

.photo img {
  width: 100%;
  height: 100%;
}

.contact {
  padding: 1rem;
}

.contact h2 {
  font-size: 2rem;
}

.contact p {
  font-size: 1.8rem;
}

@media (max-width: 66.75em) {
  .team {
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
  }
}

@media (max-width: 41.75em) {
  .team {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 28.5em) {
  .team {
    grid-template-columns: 1fr;
  }
}
