.nav {
  align-self: flex-end;
}

.nav ul {
  display: flex;
  justify-content: space-between;
  /* align-self: flex-end; */
  list-style: none;
  gap: 2rem;
}

.nav a {
  font-size: 1.8rem;
  font-weight: 600;
  text-decoration: none;
  color: #fff;
  padding: 1rem 2rem;
  border-radius: 8px;
}

.nav a:hover {
  background-color: #936214;
}

@media (max-width: 58.5em) {
  .nav {
    background-color: #fff;
  }
  .nav ul {
    background-color: #fff;
    color: black;
    flex-direction: column;
    position: absolute;
    top: 7rem;
    right: 0;
    padding: 2rem;
  }

  .nav a {
    color: black;
  }
}
