.card {
  background-color: rgba(0, 0, 0, 0.3);
  width: 20rem;
  padding: 1rem;
  border: 3px solid #936214;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.8);
  color: #fff;
}

.card h1 {
  text-align: center;
  font-size: 3rem;
}

.card p {
  font-size: 1.8rem;
  text-align: center;
}
