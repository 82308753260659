.about {
  max-width: 140rem;
  margin: auto;
  background-color: #e9ecef;
  padding: 2rem;
}

.detail {
  max-width: 80rem;
  margin-bottom: 4rem;
  margin: auto;
}

.detail h1 {
  font-size: 4rem;
  text-align: center;
}

.detail p {
  font-size: 2rem;
  text-align: center;
}
