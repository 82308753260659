.body {
  max-width: 140rem;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  margin-top: 4rem;
  padding: 2rem;
  border-radius: 8px;
  row-gap: 12rem;
}

@media (max-width: 31.3em) {
  .body {
    grid-template-columns: 1fr;
  }
}
