.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black;
  color: #fff;
  padding: 2rem;
  gap: 3rem;
  padding-bottom: 0;
  margin-top: 10rem;
}

.nav ul {
  display: flex;
  justify-content: space-between;
  align-self: center;
  list-style: none;
  gap: 2rem;
}

.nav a {
  font-size: 1.8rem;
  font-weight: 600;
  text-decoration: none;
  color: #fff;
  padding: 1rem 2rem;
  border-radius: 8px;
}

.nav a:hover {
  background-color: #936214;
}

.footer p {
  font-size: 1.4rem;
  /* text-align: center; */
}

.address {
  max-width: 90rem;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 2rem;
  border: 3px solid black;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.8);
  color: #e9ecef;
  display: flex;
  gap: 4rem;
  align-items: center;
}

.address address {
  font-size: 1.8rem;
  font-weight: 500;
}

.address p {
  font-size: 1.8rem;
  font-weight: 500;
  margin-top: 2rem;
}

.address h2 {
  font-size: 2.4rem;
  color: #936214;
}

@media (max-width: 58.5em) {
  .nav ul {
    gap: 0;
    align-self: center;
  }

  .nav {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 48.9em) {
  .nav a {
    font-size: 1.2rem;
    padding: 1rem 1rem;
  }

  .footer p {
    font-size: 1rem;
  }

  .address h2 {
    font-size: 1.6rem;
  }

  .address address {
    font-size: 1rem;
  }

  .nav ul {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 3rem;
  }
}

@media (max-width: 27em) {
  .address {
    padding: 0;
  }
}

@media (max-width: 23.2em) {
  .nav ul {
    grid-template-columns: 1fr 1fr;
  }
}
