.practice {
  max-width: 140rem;
  margin: auto;
  padding: 2rem;
  background-color: rgba(0, 0, 0, 0.4);
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  color: black;
  row-gap: 4rem;
}

.areas {
  background-color: #e9ecef;
  max-width: 50rem;
  padding: 2rem;
  border: 3px solid black;
  border-left: none;
  border-right: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.8);
}

.areas h1 {
  font-size: 2.4rem;
}

.areas p {
  font-size: 2rem;
}

@media (max-width: 71.25em) {
  .areas {
    max-width: 40rem;
  }
}

@media (max-width: 58.7em) {
  .areas {
    max-width: 30rem;
  }
}

@media (max-width: 46em) {
  .practice {
    grid-template-columns: 1fr;
  }
  .areas {
    max-width: 50rem;
  }
}
