.header {
  background-color: rgba(0, 0, 0, 0.7);
  height: 6rem;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6rem;
  position: relative;
}

.logo {
  width: 20rem;
  height: 8rem;
  color: #936214;
  padding: 1.4rem;
}

.logo h1 {
  font-style: italic;
  font-size: 2rem;
  text-align: center;
}

.menu {
  display: none;
}

.menu h2 {
  margin-bottom: -0.5rem;
}

@media (max-width: 64em) {
  .nav {
    display: none;
  }

  .menu {
    display: block;
    color: #936214;
  }

  .hidden {
    display: none;
  }
}
